<!--<template>-->
<!--  <div class="warning-panel-index">-->
<!--    <img src="@/assets/big_logo.png" class="img-logo">-->
<!--    <van-row class="cs-row">-->
<!--      <van-col offset="2" span="9" class="cs"  v-for="item in data" v-bind:key="item.id" @click="to(item.to,item.id)">-->
<!--        <van-row v-if="item.id">-->
<!--          <van-col offset="2" span="5" class="cs-col">-->
<!--            <van-image class="images"-->
<!--                round-->
<!--                fit="contain"-->
<!--                :src="require('@/assets/'+item.image)"-->
<!--            />-->
<!--          </van-col>-->
<!--          <van-col offset="1" span="15">-->
<!--            <p>{{ item.name }}</p>-->
<!--          </van-col>-->
<!--        </van-row>-->
<!--      </van-col>-->
<!--    </van-row>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: "WarningPanelIndex",-->
<!--  data() {-->
<!--    return {-->
<!--      data: [-->
<!--        {-->
<!--          id: 't5',-->
<!--          name: '应收超额',-->
<!--          image: 'ysce.png'-->
<!--        },-->
<!--        {-->
<!--          id: 't3',-->
<!--          name: '散货CRM',-->
<!--          image: 'crm.png'-->
<!--        },-->
<!--        {-->
<!--          id: 't1',-->
<!--          name: '应收到期',-->
<!--          image: 'ysdq.png'-->
<!--        },-->
<!--        {-->
<!--          id: 't2',-->
<!--          name: '年协',-->
<!--          image: 'nx.png'-->
<!--        },-->
<!--        {-->
<!--          id: 't0',-->
<!--          name: '风险客户',-->
<!--          image: 'fxkh.png'-->
<!--        },-->

<!--      ],-->
<!--    }-->
<!--  },-->
<!--  mounted() {-->
<!--  },-->
<!--  methods: {-->
<!--    to(to, id) {-->
<!--      if (id == 't0') {-->
<!--        this.$router.push({path: '/WarningCustomer'})-->
<!--      }-->
<!--      else if (id == 't5') {-->
<!--        this.$router.push({path: '/WarningExceed'})-->
<!--      } else {-->
<!--        this.$router.push({path: '/WarningPanel/' + id})-->
<!--      }-->
<!--    }-->
<!--  },-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->
<!--.warning-panel-index{-->
<!--  height: 600px;-->
<!--  background-image: url('../../assets/bg.png');-->
<!--  background-attachment: fixed;-->
<!--  background-size: cover; /* 背景图覆盖整个元素 */-->
<!--  background-position: center; /* 背景图居中 */-->
<!--  background-repeat: no-repeat; /* 背景图不重复 */-->
<!--  padding-top: 350px;-->
<!--}-->

<!--.warning-panel-index .cs-row{-->
<!--  margin-bottom: 10px-->
<!--}-->

<!--.warning-panel-index .img-logo {-->
<!--  width: 70%;-->
<!--  position: fixed;-->
<!--  left: 0px;-->
<!--  bottom: 80%;-->
<!--  right: 0px;-->
<!--  margin: 0 auto;-->
<!--}-->

<!--.warning-panel-index .cs{-->
<!--  background-color: #FFFFFF;-->
<!--  border-radius: 15px;-->
<!--  height: 100%;-->
<!--  margin-bottom: 20px;-->
<!--}-->

<!--.warning-panel-index .cs .cs-col{-->
<!--  height: 50px-->
<!--}-->

<!--.warning-panel-index .cs .cs-col .images{-->
<!--  display: flex;-->
<!--  justify-content: center;-->
<!--  align-items: center;-->
<!--  height: 100%;-->
<!--}-->

<!--</style>-->
<template>
  <div style="background:white">
    <!--    <div style="text-align: center;margin: 10% 0;font-size: 28px;">请选择</div>-->
    <van-row class="role-row" v-for="item in data" v-bind:key="item.id" @click="to(item.to,item.id)">
      <van-col style="margin-top: 6%;" span="6">
        <van-image
            round
            fit="cover"
            width="70px"
            height="70px"
            :src="require('@/assets/'+item.image)"
        />
      </van-col>
      <van-col span="14" offset="1">
        <p style="font-size: 25px;margin-top: 13%;">{{ item.name }}</p>
        <p v-if="item.name1" style="font-size: 18px;margin-top: 0%">{{ item.name1 }}</p>
      </van-col>
      <van-col span="2" style="margin-top: 11%;">
        <van-icon name="arrow"/>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "WarningPanelIndex",
  data() {
    return {
      data: [
        {
          id: 't0',
          name: '风险客户',
          name1:'（当月未回款提醒）',
          image: 'fxkh.png'
        },
        {
          id: 't1',
          name: '应收到期',
          name1: '',
          image: 'ysdq.png'
        },
        {
          id: 't3',
          name: 'CRM',
          name1: '',
          image: 'crm.png'
        },
        {
          id: 't2',
          name: '年协',
          name1: '',
          image: 'nx.png'
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    to(to, id) {
      if(id=='t0'){
        this.$router.push({path: '/WarningCustomer'})
      }else{
        this.$router.push({path: '/WarningPanel/' + id})
      }
    },
  }
}
</script>

<style scoped>
.role-row {
  background: #E6E6E6;
  padding: 20px 15px;
  margin: 20px 10px;
  border-radius: 12px;
}

</style>